import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fd3b8c50 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _ac466c7e = () => interopDefault(import('../pages/create.vue' /* webpackChunkName: "pages/create" */))
const _e34709be = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _6ac1b7b4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6c90c942 = () => interopDefault(import('../pages/polls.vue' /* webpackChunkName: "pages/polls" */))
const _0b640393 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _87411d98 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _39cf2bdd = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _4b17d2d2 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _bdcbd6c6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1d6d9908 = () => interopDefault(import('../pages/_id/index.vue' /* webpackChunkName: "pages/_id/index" */))
const _728d9fa8 = () => interopDefault(import('../pages/_id/r.vue' /* webpackChunkName: "pages/_id/r" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _fd3b8c50,
    name: "about"
  }, {
    path: "/create",
    component: _ac466c7e,
    name: "create"
  }, {
    path: "/health",
    component: _e34709be,
    name: "health"
  }, {
    path: "/login",
    component: _6ac1b7b4,
    name: "login"
  }, {
    path: "/polls",
    component: _6c90c942,
    name: "polls"
  }, {
    path: "/privacy",
    component: _0b640393,
    name: "privacy"
  }, {
    path: "/profile",
    component: _87411d98,
    name: "profile"
  }, {
    path: "/signup",
    component: _39cf2bdd,
    name: "signup"
  }, {
    path: "/terms",
    component: _4b17d2d2,
    name: "terms"
  }, {
    path: "/",
    component: _bdcbd6c6,
    name: "index"
  }, {
    path: "/:id",
    component: _1d6d9908,
    name: "id"
  }, {
    path: "/:id/r",
    component: _728d9fa8,
    name: "id-r"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
