export default function({ $axios, $toast, $config, $bugsnag }) {
  $axios.defaults.baseURL = $config.api.url;
  //$axios.defaults.headers.common["Platform"] = "Api";
  $axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
  $axios.defaults.params = {};
  $axios.defaults.params["api_key"] = $config.api.key;

  $axios.onRequest(config => {
    console.log("Making request to " + config.url);
  });

  $axios.onError(error => {
    console.log(error.response);

    if (error.response === undefined) {
      $toast.show({
        type: "danger",
        message: "Network Error: Please refresh and try again."
      });
      $bugsnag.notify(new Error("Network error. Client can't connect to api"));
    }

    const statusCode = parseInt(error.response && error.response.status);
    if (statusCode === 401) {
      $toast.show({
        type: "danger",
        message: "Authentication Error: Please login and try again."
      });
    } else {
      $toast.show({
        type: "danger",
        message: error.response.data.message
      });
    }

    return Promise.resolve(false);
  });
}
