function socialMeta(options) {
  const metaTags = [];

  if (options.url) {
    metaTags.push({ name: "author", content: options.url });
    metaTags.push({ name: "publisher", content: options.url });
    metaTags.push({ property: "og:url", content: options.url });
  }

  if (options.title) {
    metaTags.push({
      name: "apple-mobile-web-app-title",
      content: options.title
    });
    metaTags.push({ property: "og:title", content: options.title });
    metaTags.push({
      property: "og:image:alt",
      content: options.title + " page cover"
    });
    metaTags.push({ name: "twitter:title", content: options.title });
  }

  if (options.description) {
    metaTags.push({ property: "og:description", content: options.description });
    metaTags.push({
      name: "twitter:description",
      content: options.description
    });
  }

  if (options.img) {
    metaTags.push({ property: "og:image", content: options.img });
    metaTags.push({ name: "twitter:image", content: options.img });
  }

  if (options.twitter) {
    metaTags.push({ name: "twitter:site", content: options.twitter });
    metaTags.push({ name: "twitter:creator", content: options.twitter });
  }

  if (options.themeColor) {
    metaTags.push({ name: "theme-color", content: options.themeColor });
  }

  if (options.locale) {
    metaTags.push({ property: "og:locale", content: options.locale });
  }

  if (options.site_name) {
    metaTags.push({ property: "og:site_name", content: options.site_name });
  }

  if (options.twitter_card) {
    metaTags.push({ name: "twitter:card", content: options.twitter_card });
  }

  return metaTags.map(tag => {
    if (tag.content !== undefined && tag.content !== null) {
      if (tag.hasOwnProperty("name")) {
        return {
          hid: tag.name,
          name: tag.name,
          content: tag.content
        };
      } else if (tag.hasOwnProperty("property")) {
        return {
          hid: tag.property,
          property: tag.property,
          content: tag.content
        };
      }
    }
  });
}

export default ({ app }, inject) => {
  inject("socialMeta", socialMeta);
};
